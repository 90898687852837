<template>
  <div class="live-script" v-if="!!goodList.length">
    <div class="head-box">
      <span class="title">直播带货脚本</span>
      <el-button type="primary" @click="saveScript">{{ isEdit ? "保存脚本" : "优化脚本" }}</el-button>
    </div>
    <div class="add-btn">
      <el-button size="small" @click="addScript(true)">添加脚本</el-button>
      <el-button size="small" @click="addGoods">添加商品</el-button>
    </div>
    <div class="script-item" v-for="(item, index) in scriptArr">
      <div class="good-item-box" v-if="item.type_content === '商品讲解/上架商品' && item.good_info">
        <img :src="item.good_info.src.url" alt="" width="40px" height="40px" />
        <div class="good-text">
          <div class="singe-line">{{ item.good_info.goods_name }}</div>
          <div>
            <span>日常价：￥{{ Number(item.good_info.daily_price).toFixed(2) }}</span>
            <span style="margin-left: 24px">直播间价：￥{{ Number(item.good_info.live_price).toFixed(2) }}</span>
          </div>
        </div>
        <div style="width: 80px">
          <el-link type="danger" :underline="false" @click="delGood(item, index)" style="float: right" v-if="isEdit">删除商品</el-link>
        </div>
        <div class="icon" :class="item.is_show ? 'expand-icon' : 'pack-icon'" @click="showGoodScript(item)"></div>
      </div>
      <template v-if="!!item.is_show">
        <div class="script-item-head">
          <div v-if="!!item.show_type" style="width: 220px; height: 32px; line-height: 32px">
            <span v-if="!!item.goods_id">{{ item.type_content }}</span>
            <template v-else>
              <span>脚本类型：</span>
              <!-- <el-input size="small" placeholder="请输入脚本类型" v-model="item.type_content" style="width: 150px" v-if="isEdit" :minlength="2" :maxlength="12"> </el-input> -->
              <el-select v-model="item.type_content" placeholder="请选择" v-if="isEdit" style="width: 150px" size="small">
                <el-option v-for="item in scriptTypeArr" :key="item" :label="item" :value="item"> </el-option>
              </el-select>
              <span v-else>{{ item.type_content }}</span>
            </template>
          </div>
          <div style="display: flex; align-items: center">
            <span>时间：</span>
            <template v-if="isEdit">
              <!-- <el-time-picker v-model="item.start_time" :default-value="+new Date('2023-1-1 00:00:00')" value-format="timestamp" format="mm:ss" style="width: 95px" size="small" popper-class="time-picker" :disabled="true"> </el-time-picker>
              <span style="margin: 0 4px">-</span>
              <el-time-picker v-model="item.end_time" @change="setEndTime(item, index)" :default-value="+new Date('2023-1-1 00:00:00')" value-format="timestamp" format="mm:ss" style="width: 95px" size="small" popper-class="time-picker"> </el-time-picker> -->
              <el-input-number size="small" v-model.number="item.script_time[0]" @change="setEndTime(item, index)" style="width: 60px; margin-right: 8px" :min="0" :max="59" :step="1" :step-strictly="true" :controls="false"></el-input-number>
              <span>分</span>
              <el-input-number size="small" v-model.number="item.script_time[1]" @change="setEndTime(item, index)" style="width: 60px; margin: 0 8px" :min="item.script_time[0] === 0 ? 1 : 0" :max="59" :step="1" :step-strictly="true" :controls="false"></el-input-number>
              <span>秒</span>
            </template>
            <!-- <span v-else>{{ item.time_text }}</span> -->
            <span v-else>{{ item.script_time[0] }} 分 {{ item.script_time[1] }} 秒</span>
          </div>
          <div style="width: 60px">
            <el-link type="danger" :underline="false" @click="delScript(item, index)" v-if="isEdit">{{ item.show_type && item.script_type !== 1 ? "" : "删除脚本" }}</el-link>
          </div>
        </div>
        <el-input type="textarea" :rows="3" placeholder="请输入话术脚本，0-500个字符" :minlength="0" :maxlength="500" show-word-limit v-model="item.script_content" :disabled="!isEdit"> </el-input>
        <div style="height: 20px; margin-top: 8px">
          <el-link :underline="false" @click="addScript(false, item, index)" style="float: right">新增脚本</el-link>
        </div>
      </template>
    </div>
    <!-- 弹窗 -->
    <el-dialog title="添加商品" :visible.sync="dialogVisible">
      <el-table ref="multipleTable" :data="goodList" :row-key="getRowKey" @selection-change="changeChoiceGoods" style="border: #dcdcdc 1px solid; border-radius: 8px" :cell-style="{ height: '60px', color: '#343442', fontSize: '14px', fontFamily: 'PingFang SC', fontWeight: 400 }" :header-cell-style="{ fontWeight: 500, color: '#14141C', background: 'rgba(245,245,245,1)', height: '60px' }" :height="500">
        <el-table-column :reserve-selection="true" type="selection" width="50"> </el-table-column>
        <el-table-column prop="image_url" label="商品信息" align="left">
          <template slot-scope="scope">
            <div class="goods-info" @click="gotoDetail(scope.row)">
              <div style="width: 58px">
                <img :src="scope.row.src.url" alt="" width="58px" height="58px" />
              </div>
              <span class="text-overflow-2">{{ scope.row.goods_name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="daily_price" label="日常价" align="right">
          <template slot-scope="scope">
            <span>￥{{ Number(scope.row.daily_price).toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="live_price" label="直播间价" align="right">
          <template slot-scope="scope">
            <span>￥{{ Number(scope.row.live_price).toFixed(2) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination class="pages-center" :current-page="pages.currentPageNum" :page-size="pages.eachPageNum" layout="prev, pager, next, jumper" :total="pages.total" @current-change="currentChange"></el-pagination> -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="sureChoiceGoods">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LiveScript",
  components: {},
  props: {},
  data() {
    return {
      scriptArr: [],
      goodScriptArr: [],
      dialogVisible: false,
      goodList: [],
      choiceGoods: [],
      isEdit: true,
      pages: {
        currentPageNum: 1,
        eachPageNum: 5,
        total: 0,
      },
      // showScript: void 0,
      scriptTypeArr: ["开场预热", "拉互动", "促单", "讲产品", "讲卖点", "说价格", "直播收尾"],
    };
  },
  methods: {
    addScript(type, data, index) {
      this.isEdit = true;
      let item = { is_show: true, script_time: [], sleep_time: 0, start_time: 0, end_time: 0, script_type: data && !!data.goods_id ? 5 : 1, type_content: void 0, script_content: void 0, goods_id: data ? data.goods_id : void 0 };
      type ? this.scriptArr.push({ ...item, ...{ show_type: true } }) : this.scriptArr.splice(index + 1, 0, item);
    },
    getDelIndex(data, index) {
      for (let i = index + 1; i < data.length; i++) {
        if (data[i].show_type) return i;
      }
    },
    delScript(item, index) {
      if (item.show_type && !item.goods_id) {
        let nextIndex = this.getDelIndex(this.scriptArr, index);
        !!nextIndex ? this.scriptArr.splice(index, nextIndex - index) : this.scriptArr.splice(index, this.scriptArr.length - index);
      } else {
        this.scriptArr.splice(index, 1);
      }
      // this.scriptArr[0].start_time = +new Date("2023-1-1 00:00:00");
    },
    setEndTime(item, index) {
      // if (index !== 0) {
      //   if (this.scriptArr[index - 1].end_time) item.start_time = this.scriptArr[index - 1].end_time;
      //   if (!!item.start_time && !!item.end_time && item.end_time <= item.start_time) return this.$message.error("结束时间不能小于开始时间！");
      //   if (!!this.scriptArr[index + 1]) this.scriptArr[index + 1].start_time = item.end_time;
      //   if (!!this.scriptArr[index + 1]?.end_time && !!item.end_time && item.end_time >= this.scriptArr[index + 1].end_time) return this.$message.error("结束时间不能大于下一个脚本的结束时间！");
      // } else {
      //   item.start_time = +new Date("2023-1-1 00:00:00");
      //   if (!!this.scriptArr[index + 1]) this.scriptArr[index + 1].start_time = item.end_time;
      // }
      if (index !== 0) {
        if (!!this.scriptArr[index - 1] && this.scriptArr[index - 1].script_time.length === 2 && item.script_time.length === 2) {
          let previousTimeSum = this.scriptArr[index - 1].script_time[0] * 60 + this.scriptArr[index - 1].script_time[1];
          let currentTimeSum = item.script_time[0] * 60 + item.script_time[1];
          if (currentTimeSum <= previousTimeSum) return this.$message.error("当前结束时间需大于上一个脚本的结束时间！");
        }
        if (!!this.scriptArr[index + 1] && this.scriptArr[index + 1].script_time.length === 2 && item.script_time.length === 2) {
          let nextTimeSum = this.scriptArr[index + 1].script_time[0] * 60 + this.scriptArr[index + 1].script_time[1];
          let currentTimeSum = item.script_time[0] * 60 + item.script_time[1];
          if (currentTimeSum >= nextTimeSum) return this.$message.error("当前结束时间需小于下一个脚本的结束时间！");
        }
      }
    },
    showCheckGoods() {
      this.$nextTick(() => {
        this.$refs.multipleTable.clearSelection();
        // console.log('选中商品ID', this.goodScriptArr);
        this.goodList.map((item) => {
          if (this.goodScriptArr.includes(item.live_goods_id)) {
            this.$refs.multipleTable.toggleRowSelection(item, true);
          }
        });
      });
    },
    addGoods() {
      if (!this.goodList.length) return this.$message.error("暂无商品数据！");
      this.pages.currentPageNum = 1;
      Promise.all([this.getGoodsList()]).then((res) => {
        this.isEdit = true;
        this.dialogVisible = true;
        this.showCheckGoods();
      });
    },
    delGood(item, index) {
      let $index = this.choiceGoods.findIndex((good) => good.live_goods_id === item.goods_id);
      let $order = this.goodScriptArr.findIndex((data) => data === item.goods_id);
      this.scriptArr = this.scriptArr.filter((data) => data.goods_id !== item.goods_id);
      if ($index !== -1) this.choiceGoods.splice($index, 1);
      if ($order !== -1) this.goodScriptArr.splice($order, 1);
      // this.scriptArr[0].start_time = +new Date("2023-1-1 00:00:00");
    },
    showGoodScript(data) {
      this.scriptArr.map((item) => {
        if (data.goods_id && item.goods_id === data.goods_id) this.$set(item, "is_show", !item.is_show);
      });
    },
    getRowKey(row) {
      return row.live_goods_id;
    },
    changeChoiceGoods(val) {
      this.choiceGoods = val;
      // val.map((good) => {
      //   good.goods_id = good.id;
      //   if (!this.goodScriptArr.includes(good.id)) {
      //     this.choiceGoods.push(good);
      //     this.goodScriptArr.push(good.id);
      //   }
      // });
      // console.log({ val, choiceGoods: this.choiceGoods });
    },
    sureChoiceGoods() {
      let goodsArr = [];
      // let LastTimeCheck = Array.from(new Set(this.scriptArr.map((item) => item.goods_id)));
      // LastTimeCheck = LastTimeCheck.filter((item) => !!item !== false);
      this.choiceGoods.map((good) => {
        // if (!LastTimeCheck.includes(good.live_goods_id)) {
        if (!this.goodScriptArr.includes(good.live_goods_id)) {
          goodsArr.push({ is_show: true, script_time: [], sleep_time: 0, start_time: 0, end_time: 0, script_type: 5, show_type: true, type_content: "商品讲解/上架商品", script_content: void 0, goods_id: good.live_goods_id, good_info: good }, { is_show: true, script_time: [], sleep_time: 0, start_time: 0, end_time: 0, script_type: 5, show_type: true, type_content: "商品卖点", script_content: void 0, goods_id: good.live_goods_id }, { is_show: true, script_time: [], sleep_time: 0, start_time: 0, end_time: 0, script_type: 5, show_type: true, type_content: "优惠券/发布优惠券", script_content: void 0, goods_id: good.live_goods_id }, { is_show: true, script_time: [], sleep_time: 0, start_time: 0, end_time: 0, script_type: 5, show_type: true, type_content: "商品问答", script_content: void 0, goods_id: good.live_goods_id });
        }
      });
      this.scriptArr = [...this.scriptArr, ...goodsArr];
      this.goodScriptArr = Array.from(new Set(this.choiceGoods.map((item) => item.live_goods_id)));
      // console.log("确定商品ID", this.goodScriptArr);
      this.scriptArr.map((item) => {
        if (!this.goodScriptArr.includes(item.goods_id) && !!item.goods_id) {
          let startindex = this.scriptArr.findIndex((data) => data.goods_id === item.goods_id);
          let endindex = this.scriptArr.findLastIndex((data) => data.goods_id === item.goods_id);
          this.scriptArr.splice(startindex, endindex - startindex + 1);
        }
      });
      this.dialogVisible = false;
    },
    saveScript() {
      if (this.isEdit) {
        if (!this.scriptArr.length) return this.$message.error("请添加脚本！");
        let sign = this.scriptArr.every((item) => {
          // return !!item.start_time && !!item.end_time && !!item.script_content;
          return item.script_time.length === 2 && !!item.script_content;
        });
        if (!sign) return this.$message.error("请完善数据！");
        // this.scriptArr.map((item) => {
        //   item.sleep_time = (item.end_time - item.start_time) / 1000;
        //   item.time_text = `${Math.floor(item.sleep_time / 60)}分${item.sleep_time % 60}秒`;
        // });
        let params = JSON.parse(JSON.stringify(this.scriptArr));
        params.map((item) => {
          // item.start_time = (item.start_time - new Date("2023-1-1 00:00:00")) / 1000;
          // item.end_time = (item.end_time - new Date("2023-1-1 00:00:00")) / 1000;
          if (!item.type_content) item.type_content = "";
          if (!item.goods_id) item.goods_id = 0;
          Object.keys(item).map((key) => {
            let keys = ["script_time", "start_time", "end_time", "sleep_time", "script_type", "type_content", "script_content", "goods_id"];
            if (!keys.includes(key)) delete item[key];
          });
        });
        this.$http.axiosPost(this.$api.operateScript, { data: params }, (res) => {
          if (res.code == 200) {
            this.isEdit = false;
            this.$message.success(res.msg);
          } else {
            this.isEdit = true;
            this.$message.warning(res.msg);
          }
        });
      } else {
        this.isEdit = !this.isEdit;
        this.getScriptList();
      }
    },
    currentChange(val) {
      this.pages.currentPageNum = val;
      this.getGoodsList();
    },
    getGoodsList() {
      return new Promise((resolve, reject) => {
        // let params = { paging: 1, page: this.pages.currentPageNum, page_size: this.pages.eachPageNum };
        let params = { paging: 0 };
        this.$http.axiosGetBy(this.$api.liveGoodList, params, (res) => {
          if (res.code == 200) {
            resolve(res.data);
            if (!!res.data.list) {
              this.goodList = res.data.list;
              this.pages.total = res.data.total;
            }
            this.goodList.map((good) => {
              good.live_goods_id = good.id;
            });
            // this.showCheckGoods();
          } else {
            reject(new Error(`${res.msg}`));
          }
        });
      });
    },
    getScriptList() {
      this.$http.axiosGet(this.$api.getScriptInfo, (res) => {
        if (res.code == 200) {
          res.data.list.map((item) => {
            item.is_show = true;
            // item.start_time = item.start_time * 1000 + new Date("2023-1-1 00:00:00").getTime();
            // item.end_time = item.end_time * 1000 + new Date("2023-1-1 00:00:00").getTime();
            if (!!item.type_content) item.show_type = true;
            if (!!item.goods_id) {
              this.goodList.map((good) => {
                if (item.goods_id === good.id && item.type_content === "商品讲解/上架商品") {
                  item.good_info = good;
                  item.good_info.goods_id = item.good_info.id;
                  this.choiceGoods.push(good);
                }
              });
            }
          });
          this.$nextTick(() => {
            this.scriptArr = res.data.list;
            // this.showScript = true;
            this.goodScriptArr = Array.from(new Set(this.scriptArr.map((item) => item.goods_id)));
            this.goodScriptArr = this.goodScriptArr.filter((item) => !!item !== false);
          });
        }
      });
    },
  },
  mounted() {
    Promise.all([this.getGoodsList()]).then((res) => {
      this.getScriptList();
    });
  },
  computed: {
    ...mapState(["live_good_id", "live_goodsList"]),
    listenChange() {
      const goodsList = this.live_goodsList;
      const good_id = this.live_good_id;
      return { goodsList, good_id };
    },
  },
  watch: {
    listenChange(val) {
      setTimeout(() => {
        let goodList = JSON.parse(JSON.stringify(val.goodsList));
        let editGood = goodList.find((item) => item.id === val.good_id);
        if (!!editGood) {
          this.scriptArr.map((item) => {
            if (item.goods_id === editGood.id) this.$set(item, "good_info", editGood);
          });
        }
      }, 100);
    },
  },
};
</script>

<style scoped lang="scss">
.live-script {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 20px 24px;
  overflow-y: auto;
  .head-box {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid #dcdcdc;
    .title {
      font-size: 24px;
      font-weight: bold;
    }
  }
  .add-btn {
    padding: 16px 0;
  }
  .script-item {
    margin-bottom: 12px;
    .script-item-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
    }
  }
  .goods-info {
    display: flex;
    align-items: center;
    span {
      margin-left: 10px;
    }
  }
  .good-item-box {
    padding: 16px;
    background: #f6f6f6;
    margin-bottom: 12px;
    display: flex;
    position: relative;
    .good-text {
      width: 360px;
      margin-left: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .icon {
      position: absolute;
      bottom: -4px;
      left: 50%;
      margin-left: -4px;
      cursor: pointer;
    }
    .expand-icon {
      border-top: 8px solid #333;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
    }
    .pack-icon {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #333;
      margin-left: -12px;
    }
  }
  .singe-line {
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
  }
}
</style>
<style>
.el-table .el-table__cell.gutter {
  background: #f5f5f5;
}
</style>
